import React from 'react';
import profileIcon from '../assets/user.png'
import logo from '../assets/logo-home.png'
import firebase from 'firebase/compat/app';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";

const RightSection=({user})=>{

  const [anchorEl, setAnchorEl] = React.useState()
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
      setAnchorEl(null);
      navigate("/");
      window.location.reload();

    }).catch(function(error) {
      // An error happened.
      console.log(error)
    });
  };

    return <div className='right-section'>
         <nav class="header-nav ms-auto">
      <ul class="d-flex align-items-center">

        <li class="nav-item d-block d-lg-none">
          <a class="nav-link nav-icon search-bar-toggle " href="#">
            <i class="bi bi-search"></i>
          </a>
        </li>

        <li class="nav-item dropdown">

          <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
            <i class="bi bi-bell"></i>
            <span class="badge bg-primary badge-number">4</span>
          </a>

     

        </li>

        <li class="nav-item dropdown">

          <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
            <i class="bi bi-chat-left-text"></i>
            <span class="badge bg-success badge-number">3</span>
          </a>

        </li>

        <li class="nav-item dropdown pe-3">

          <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown" onClick={handleClick}>
            <img src={profileIcon} alt="Profile" class="rounded-circle"/>
            <span class="d-none d-md-block dropdown-toggle ps-2">Mr. {user?.claims?.name}</span>
          </a>
          <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleLogOut}>Logout</MenuItem>
      </Menu>
 
        </li>

      </ul>
    </nav>
    </div>
}

const SearchBar=()=>{
    return <div className='search-form d-flex align-items-center'>
        <div class="search-bar">
      <form class="search-form d-flex align-items-center" method="POST" action="#">
        <input type="text" name="query" placeholder="Search" title="Enter search keyword"/>
        <button  title="Search"><i class="bi bi-search"></i></button>
      </form>
    </div>
    </div>
}

export const HomeBanner=({user})=>{
    return <div className='home_banner'>
    <img src={logo} alt="logo" className="logo"/>
    {/* <div className='logo-text'>Kiranoz Admin</div> */}
    <SearchBar/>
     <RightSection user={user}/>

   

    </div>
}