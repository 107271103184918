import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import profileIcon from '../../assets/user.png'

import { getAPI, putAPI } from "../../services/apiService"
import { GET_RETURN_ORDERS_URL, UPDATE_ORDERS_URL } from "../../services/apiConstant"
import './refund.css';
import OrderItemPopup from '../orders/orderItemPopup'
import { ReturnStatus } from '../orders/OrderEnum'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Loader } from '../../component/loader';

const RefundList = (props) => {
  const [loading, setLoading] = useState(false);
  const [showProductsPopup, setShowProductsPopup] = useState(false);
  const [selectedOrderData, setSelectedOrderData] = useState(null);
  const [selectedTab, setSelectedTab] = React.useState(ReturnStatus.REFUND_SEND);
  const [refundOrderList, setRefundOrderList] = useState([]);


  useEffect(() => {
    fetchReturnOrderList(ReturnStatus.REFUND_SEND)
  }, [])


  const fetchReturnOrderList = async (returnStatus) => {
    setLoading(true)
    try {
      let url = GET_RETURN_ORDERS_URL + "?returnStatus=" + returnStatus
      const orderResponse = await getAPI(url);
      console.log('orderResponse', orderResponse)

      if (orderResponse) {
        setRefundOrderList(orderResponse)
      }
    }
    catch (e) {
      console.log('orderResponse fail', e)

    }

    setLoading(false)

  }

  const handleShowDetails = (data) => {
    setSelectedOrderData(data)
    setShowProductsPopup(true)
  };




  const handleRefund = async (data) => {
    setLoading(true);
    data.paymentInfo.paymentStatus = "refunded"
    data.lastUpdatedTime =  Date.now()
    data.completedBy = "AdminPortal-Account"
    data.returnStatus = ReturnStatus.REFUND_SETTLE
    await putAPI(UPDATE_ORDERS_URL + data.id, data).catch(e => console.log(e));
    await fetchReturnOrderList(ReturnStatus.REFUND_SEND).catch(e => console.log(e));
    setLoading(false);

  }


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    fetchReturnOrderList(newValue)
  };

  const isSuperAdmin = () => {
    return (props?.user?.claims?.role === 'admin') || (props?.user?.claims?.role === 'superAdmin')
  }

  const OrderView = () => {
    return <div class="product-card ">
      <div class="card-body">
      {refundOrderList?.length !== 0 && 
        <div className='title-container'>
          <h5 class="card-title ">{'Refund List'}  </h5>
          <h5 class="card-title ">{`Total (${refundOrderList?.length})`}  </h5>
        </div>
  }
        {refundOrderList?.length === 0 && <h5 style={{textAlign:'center',padding:15}}>No Order...</h5>}
        {loading && <Loader loading={loading} />}
        {refundOrderList && refundOrderList.map((item, index) => {
          return <div className='wrapper-user' key={"member" + index}>
            <div className='row-home mt-20'>
              <img src={profileIcon} alt="profile" width="100" height="100" className='online' />
              <div className='column-home ml-20'>
                <div className='name'> {item?.walletResponse?.fullName}</div>
                <div><span>Mobile:</span> {item?.walletResponse?.mobileNumber}</div>
                <div><span>Bank Account Number:</span> {item?.walletResponse?.bankAccountNumber || '----'}</div>
                <div><span>Bank Name:</span> {item?.walletResponse?.bankName}</div>
                <div><span>Bank IFSC:</span> {item?.walletResponse?.ifscCode}</div>
                <div><span>Amount to be refund:</span> {item?.totalRefundAmount}</div>
                <div><span>Refund Status:</span> {item?.paymentInfo.paymentStatus}</div>
                <div><span>Return Status:</span> {item?.returnStatus}</div>




              </div>

            </div>
              <div style={{ display: 'flex', flexDirection: 'row', margin: 10, alignSelf: 'flex-end', justifyContent: 'space-between' }} >
              {
              (item?.returnStatus===ReturnStatus.REFUND_SEND) ?
              <>
                <Button className='primary-button bg-black' style={{ width: 150, marginRight: 10 }} onClick={() => handleShowDetails(item)}>{"Product Details"}</Button>
                <Button className='primary-button' style={{ width: 150 }} onClick={() => handleRefund(item)}>{"Settle Payment"}</Button>
                </>:
                <div style={{fontSize:18,fontWeight:'600',color:'green'}}>Closed</div>
              }
                </div>
         
          

            {/* { isSuperAdmin() && <i class="bi bi-trash trash" onClick={() => handleDelete(item)}></i>} */}
          </div>
        })}

      </div>

    </div>
  }


  return <>
    <Box sx={{ width: '100%', typography: 'body1' }}>

      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="refundorderlist"  >
            <Tab label="Active Return Orders" value={ReturnStatus.REFUND_SEND} />
            <Tab label="Other refund Orders" value={'Other_Refund'} />

            <Tab label="Completed Return Orders" value={ReturnStatus.REFUND_SETTLE} />
          </TabList>
        </Box>
        <TabPanel value={ReturnStatus.REFUND_SEND}><OrderView /></TabPanel>
        <TabPanel value={"Other_Refund"}><OrderView /></TabPanel>
        <TabPanel value={ReturnStatus.REFUND_SETTLE}><OrderView /></TabPanel>


      </TabContext>
      {showProductsPopup && <OrderItemPopup data={selectedOrderData} onCloseProductItemPopup={() => setShowProductsPopup(false)} show={true} />}


    </Box>

  </>
}


export default RefundList;