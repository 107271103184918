import React, { useEffect, useState } from 'react';
import './style.css';
import IconCard from '../../../component/iconCard'
import CardIMage from '../../../component/cardImage'
import addMoreIcon from '../../../assets/more.png'
import Button from 'react-bootstrap/Button';
import AddCategoryPopup from './AddCategoryPopup';
import UpdateCategoryPopup from './updateCategoryPopup';
import { getCatalogueList,updateCatalogue } from '../../../redux/actions/catalogueAction';
import { useDispatch } from "react-redux";
import { Loader } from '../../../component/loader';
import {SearchBar} from "../../../component/searchBar"


 const CatalogueDetailsCard=({list,handlePageSelect,selectedData,user})=>{
    const [showCatalogue, setShowCatalogue] = useState(false)
    const [showUpdateCatalogue, setShowUpdateCatalogue] = useState(false)
    const [selectItem,setSelectedItem]=useState(null);
    const [loading, setLoading] = useState(false);
    const [categoryList,setCategoryList]=useState([]);
    const [searchText,setSearchText]=useState()

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true)
      }, [])

      useEffect(() => {
        if(list){
            console.log('list**',list)
            console.log('selectedData**',selectedData)

            setCategoryList(list)
            if(selectedData?.categoryName){
            const filterList=list.filter((item)=>(item.categoryType===selectedData?.categoryName?.replace(/ +/g, "")) || item.categoryType===selectedData?.categoryName ||  item.mainCategoryId===selectedData?.id)  || []
                setCategoryList(filterList)

            }
            setLoading(false)
        }
     
      }, [list])


      const handleCatalogueSow=()=>{
         setShowCatalogue(!showCatalogue)
         if(showCatalogue){
            dispatch(getCatalogueList());
         }
          }

          const handleUpdateCatalogueShow=(data)=>{
            console.log('handleUpdateCatalogueShow data',data);
            setSelectedItem(data)
            setShowUpdateCatalogue(!showUpdateCatalogue)
            if(showUpdateCatalogue){
               dispatch(getCatalogueList());
            }
             }

             const onUpdateCatalogue=(data)=>{
                setShowUpdateCatalogue(false)
                setSelectedItem(null)
                if(showUpdateCatalogue){
                   dispatch(getCatalogueList());
                }
                 }

    const handleToggleChange=(data)=>{
        dispatch(updateCatalogue(data));
        
  //alert(value)
    }
    const showProductList=(data)=>{
     console.log('selected data',data);
     handlePageSelect('productList',data);
    }
   
    const handleTextSearch = (event) => {
        const key=event.target.value
        if(!key){
            setCategoryList(list)
        }
        else{
            const filterresult = categoryList.filter(order => (order.itemName?.toLowerCase()?.includes(key.toLowerCase()) || order?.desc?.toLowerCase()?.includes(key.toLowerCase()) || order?.categoryType?.toLowerCase()?.includes(key.toLowerCase())))
            setCategoryList(filterresult)
        }
        event.preventDefault()
      
        };

    return <>
     <AddCategoryPopup showCatalogue={showCatalogue} handleCloseCatalogue={handleCatalogueSow} user={user}/>
    <UpdateCategoryPopup data={selectItem} showCatalogue={showUpdateCatalogue} handleCloseCatalogue={onUpdateCatalogue} user={user}/>
    <SearchBar handleTextSearch={handleTextSearch} value={searchText} title={'Enter category name,type,description to search..'}/>

        <div class="product-card ">

        <div class="card-body">
        <div className='title-container'>
        <h5 class="card-title ">{selectedData?.categoryName || 'Product Catalogue - '} {`Total(${categoryList.length})`} </h5>
        {/* <h6 class="card-title ">Total ({list.length}) </h6> */}
        <Button onClick={handleCatalogueSow}
        className='primary-button' 
        style={{margin:"10px", padding:"10px", fontWeight:"700"}}>
            <i class="bi bi-plus-square mr-5"></i> Add New SubCategory
            </Button>

        </div>
    {/* <hr/> */}
        <div className='col-lg-12 mt-20'>
        {loading &&<Loader loading={loading}/>}
        {categoryList?.length===0 &&<center>No ctegory added</center>}


            <div className='row'>
                {categoryList && categoryList.map((data,index)=>{
                   // return <ImageCard title={data.itemName}  icon={data.url} handleToggleChange={handleToggleChange} />
                   return  <CardIMage 
                   key={`cardId${index}`}
                   data={data} 
                   handleToggleChange={handleToggleChange} 
                   handleUpdateCatalogueShow={handleUpdateCatalogueShow}
                   showProductList={showProductList}
                   />

                })}
                {/* <IconCard  title={"Add New Category"} icon={addMoreIcon} onClick={handleCatalogueSow} /> */}
            </div>
            </div>

        </div>
    </div>
    </>
}
export default CatalogueDetailsCard