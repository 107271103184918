import React from 'react';
import {useNavigate} from "react-router-dom"


 const Header=({currentPageName,lastNavigationName})=>{
  const navigate = useNavigate();
  return <>
        <div class="pagetitle">
      <h1>{currentPageName}</h1>
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/home">Home</a></li>
          <li class="breadcrumb-item active"><a href="#" onClick={()=>navigate(-1)}>{lastNavigationName}</a></li>
        </ol>
      </nav>
    </div>
    </>
}
export default Header