export const ReturnStatus={
    RETURN_PROCESS:'return-process',
    RETURN_SEND:'return-send',
    RETURN_CANCEL:'return-cancel',
    RETURN_COMPLETED:'return-completed',
    REFUND_SEND:'refund-send',
    REFUND_SETTLE:'refund-settle',

}


export const OrderStatus={
    COMPLETED:'completed',
    RETURN:'return',
    CANCELED:'canceled',
    REJECTED:'rejected',
    ACTIVE:'active',
    PENDING:'pending',
    ASSIGNED:'assigned',
    DELIVERED:'delivered',
    INTRANSIT:'intransit',
    FAILED: 'failed'
}