
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import InfoCard from './infoCard'
import RightOrderCard from './rightOrderCard'
import RightRiderCard from './rightRiderCard'
import ReportCard from './reportCard'
import SalesCard from './salesCard';
import MainCategoryCard from './mainCategory'
import Header from '../../../component/header';
import { useDispatch, useSelector } from "react-redux";
import { getActiveOrderList,getPendingOrderList} from '../../../redux/actions/orderAction';
import { getCustomerList } from '../../../redux/actions/customerAction';

import '../home.css';



  const DashBoard=({user})=>{

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activrOderArray,setActiveOrderArray]=useState([])
    const [reayToShipOderArray,setReayToShipOderArray]=useState([])

    const [customerCount,setCustomerCount]=useState()


    const activeOrderList = useSelector((state) => state.getActiveOrderReducer);
    const pendingOrderList = useSelector((state) => state.getPendingOrderReducer);

    const customerResponse = useSelector((state) => state.customerReducer);


    useEffect(() => {
    dispatch(getActiveOrderList());
    dispatch(getPendingOrderList("pending"));
    dispatch(getCustomerList());
  }, [])

  useEffect(() => {
    if(activeOrderList){
      console.log('activeOrderList',activeOrderList)
      setActiveOrderArray(activeOrderList)
    }

}, [activeOrderList])

useEffect(() => {
  if(pendingOrderList){
    console.log('pendingOrderList',pendingOrderList)
    setReayToShipOderArray(pendingOrderList)
  }

}, [pendingOrderList])

useEffect(() => {
  if(customerResponse){
    console.log('customerResponse',customerResponse)
  setCustomerCount(customerResponse)
  }

}, [customerResponse])

const handlePageSelect=(page, data)=>{
  navigate("/"+page,{state:{data:data}})
  
}


    return <>
        {/* <Header /> */}
        <div className='row-home'>
          <div className='col-lg-8'>
            <div className='row'>
              <InfoCard title={'Active Order'} subtitle={'Total'} icon={'bi bi-cart'} data={{count:activrOderArray?.length}} cardName={'sales-card'}/>
              <InfoCard title={'Customers'} subtitle={'Total'}  icon={'bi bi-people'} data={{count:customerCount?.count||0}} cardName={'customers-card'}/>
              <InfoCard  title={'Revenue'}  subtitle={'This month'} icon={'bi bi-currency-dollar'} data={{count:'NA'}} cardName={'revenue-card'}/>
              <div className='column-home'>
                <MainCategoryCard handlePageSelect={handlePageSelect} user={user}/>
              <ReportCard/>
              <SalesCard/>
              </div>
            </div>
          </div>
          <div className='rightContainer'>
          <RightOrderCard title={'Ready to Ship Orders'} subtitle={`Total (${reayToShipOderArray.length})`} list={reayToShipOderArray} handlePageSelect={handlePageSelect}/>
          <RightRiderCard title={'Agents'} subtitle={'Nearby'} list={[]}/>
          <RightOrderCard title={'Top Products'} subtitle={'Today'} list={[]}/>
          </div>
        </div>
    </>
  }

  export default DashBoard;