import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { getProductsByCategory,updateProduct,clearUpdateProduct} from '../../redux/actions/productAction';
import { Loader } from '../../component/loader';
import ProductListItem from './component/productListItem'
import {deleteAPI} from "../../services/apiService"
import {DELETE_PRODUCT_URL} from "../../services/apiConstant"
import Confirm  from './component/ConfirmPopup'
import {SearchBar} from "../../component/searchBar"
import {useLocation} from 'react-router-dom';
import './product.css';
import AddProductPopup from './component/AddProductPopup';
import Header from '../../component/header';



const FOLDER_PATH='Products'



const ProductList = (props) => {
  const location = useLocation();
  const selectedData= location?.state?.data
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const [productAllList, setProductAllList] = useState([]);
  const [productAllListCopy, setProductAllListCopy] = useState([]);

  const[showDelete,setShowDelete]=useState(false)
  const [searchText,setSearchText]=useState()


  const [showCatalogue, setShowCatalogue] = useState(false)
  const categoryId=selectedData?.id


  useEffect(() => {
    setLoading(true)
  dispatch(getProductsByCategory(categoryId))
  }, [])
  
  const productList = useSelector((state) => state.getProductByCategoryReducer) || [];
  const updateProductResponse = useSelector((state) => state.updateProductReducer) || [];
  console.log('updateProductResponse',updateProductResponse)


  useEffect(() => {
    setLoading(false)
    console.log('productList',productList)
    if(productList?.length>0){
      setLoading(false)
      setProductAllList(productList)
      setProductAllListCopy(productList)
    }
}, [productList])

useEffect(() => {
  if(updateProductResponse){
  //dispatch(getProductsByCategory(categoryId))
   dispatch(clearUpdateProduct())
  
  }
}, [updateProductResponse])


  

const handleDelete=(item)=>{
  setShowDelete(true)
  setSelectedItem(item)

}

const onDeleteConfirm=()=>{
  deleteItem();
  setShowDelete(false)
  setSelectedItem(null)

}

const deleteItem=async()=>{
  try{
    const item=selectedItem
    const body={"imagePath":`${FOLDER_PATH}/${selectedData?.itemName}/${item?.imageName}`}

    setLoading(true)
    const deleteProductResponse= await deleteAPI(DELETE_PRODUCT_URL+item.id,body);
    console.log('deleteProductResponse',deleteProductResponse)
    dispatch(getProductsByCategory(categoryId));
    setLoading(false)

  }
  catch(e){
  console.log(e)
  setLoading(false)
  alert("error in delete")

  }
}

const handleToggleChange=(item)=>{
  dispatch(updateProduct(item));
  //dispatch(getProductsByCategory(categoryId))


}

const toggleAddProductPopup=()=>{
  setShowCatalogue(!showCatalogue)
  if(showCatalogue){
        dispatch(getProductsByCategory(categoryId));
     }
     else{
      setSelectedItem(null)
     }
}

const handleEdit=(item)=>{
  if(!item.imageArrayUrl || item.imageArrayUrl?.length===0){
    const arr =[item.imageUrl]
    item['imageArrayUrl']=arr
  }
  setSelectedItem(item)
  setShowCatalogue(true)

}


  const handleTextSearch = (event) => {
    setSearchText(event.target.value)
    const key = event.target.value
    if (!key) {
      setProductAllList(productAllListCopy)
    }
    else {
      const filterresult = productAllList.filter(order => (order.itemName?.toLowerCase()?.includes(key.toLowerCase()) ||
       order?.desc?.toLowerCase()?.includes(key.toLowerCase())) || order?.id?.includes(key))
      setProductAllList(filterresult)
    }
    event.preventDefault()

  };


  return <>
       {showCatalogue &&<AddProductPopup showCatalogue={showCatalogue} toggleAddProductPopup={toggleAddProductPopup} categoryDetails={selectedData} selectedItem={selectedItem}/>}

      <div className='row-home'>
            {showDelete &&<Confirm
                    onConfirm={onDeleteConfirm}
                    onCancel={()=>setShowDelete(false)}
                    body="Are you sure you want to delete this?"
                    title={`Deleting - ${selectedItem?.itemName}`}/>
             }

      <div className='col-lg-8'>
        <div className='row'>
          <div style={{display:'flex', justifyContent:'space-between'}}>
        <Header currentPageName={"Product List"} lastNavigationName={'catalogue'}/>
        <SearchBar handleTextSearch={handleTextSearch} value={searchText} title={'Enter product name to search..'}/>
        </div>

          <div className='column-home'>
            <div class="product-card ">

              <div class="card-body">
                <div className='title-container'>
                  <h5 class="card-title ">{selectedData?.itemName?.toUpperCase()}  </h5>
                  <h6 class="card-title ">Total ({productAllList.length}) </h6>
                  <Button onClick={toggleAddProductPopup}
                    className='primary-button'
                    style={{ margin: "10px", padding: "10px", fontWeight: "700" }}>
                    <i class="bi bi-plus-square mr-5"></i> Add New Product
                  </Button>

                </div>

                <ListGroup as="ol" className='mt-20'>
                {loading &&<Loader loading={loading}/>}

                  {productAllList && productAllList.length>0 ? productAllList.map((item,index) => {
                    return <>
                       <ProductListItem item={item} index={index} handleDelete={handleDelete}  handleToggleChange={handleToggleChange}  handleEdit={handleEdit} user={props.user}/>
                    </>
                  }):<div style={{textAlign:'center'}}> No Product Listed yet!! Please Add New Product from Top Right button.</div>}


                </ListGroup>

              </div>

            </div>
          </div>
        </div>

      </div>

    </div>
  </>
}

export default ProductList;