import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { getActiveOrderList } from '../../redux/actions/orderAction';
import { UPDATE_ORDERS_URL, CANCEL_ORDERS_URL, RETURN_ORDERS_URL } from "../../services/apiConstant"
import { putAPI, postAPI } from "../../services/apiService"
import { SearchBar } from "../../component/searchBar"
import './order.css';
import ProcessOrderPopup from './ProcessOrderPopup';
import AssignOrderPopup from './AssignOrderPopup';
import OrderItemPopup from './orderItemPopup'
import BankDetailsPopup from './bankDetailsPopup'
import SendRefundPopup from './sendRefundPopup'
import { ReturnStatus, OrderStatus } from './OrderEnum'
import moment from 'moment';

import { Loader } from '../../component/loader';

const OrderList = (props) => {
  const dispatch = useDispatch();
  const [activrOderArray, setActiveOrderArray] = useState([])
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedTab, setSelectedTab] = React.useState(OrderStatus.ACTIVE);
  const [searchText, setSearchText] = useState()
  const [showAssignCourierPopup, setShowAssignCourierPopup] = useState(false);

  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [showProductsPopup, setShowProductsPopup] = useState(false);
  const [selectedOrderData, setSelectedOrderData] = useState(null);
  const [selectedOrderDataForBank, setSelectedOrderDataForBank] = useState(null);
  const [selectedRefundDataForOutOfStock, setSelectedRefundDataForOutOfStock] = useState(null);

  const [showBankInfoPopup, setShowBankInfoPopup] = useState(false);
  const [showRefundPopup, setShowRefundPopup] = useState(false);



  const [selectedAssignData, setSelectedAssignData] = useState();



  const activeOrderList = useSelector((state) => state.getActiveOrderReducer);


  useEffect(() => {
    setLoading(true)
    dispatch(getActiveOrderList());
  }, [])

  useEffect(() => {
    if (activeOrderList) {
      console.log('activeOrderList', activeOrderList)
      setLoading(false)
      setActiveOrderArray(activeOrderList)
    }

  }, [activeOrderList])

  useEffect(() => {
    if (selectedOrderData) {
      setShowProductsPopup(true)
    }
  }, [selectedOrderData])


  const getAddress = (addressDetails) => {
    if (addressDetails) {
      return `${addressDetails.fullName}, ${addressDetails.addressLine1}, ${addressDetails.addressLine2}, ${addressDetails.city}, ${addressDetails.state}, ${addressDetails.pincode}`

    }
    return ''
  }
  const updateOrderStatus = async (data, status = OrderStatus.COMPLETED, submitOrderResponse) => {
    setLoading(true);
    data.orderStatus = status
    //data.paymentInfo.paymentStatus = status === OrderStatus.COMPLETED ? status : OrderStatus.PENDING
    data.lastUpdatedTime = Date.now()
    data.completedBy = "AdminPortal"
    data.trackInfo = submitOrderResponse
    const updateorderResponse = await putAPI(UPDATE_ORDERS_URL + data.id, data);
    dispatch(getActiveOrderList(selectedTab));

  }

  const updateOrderWithoutStatus = async (data) => {
    setLoading(true);
    data.lastUpdatedTime = Date.now()
    data.completedBy = "AdminPortal"
    const updateorderResponse = await putAPI(UPDATE_ORDERS_URL + data.id, data);
    dispatch(getActiveOrderList(selectedTab));

  }


  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    dispatch(getActiveOrderList(newValue));

  };

  const handleTextSearch = (event) => {
    console.log('event', event.target.value)
    setSearchText(event.target.value)
    const key = event.target.value
    if (!key) {
      setActiveOrderArray(activeOrderList)
    }
    else {
      const filterresult = activrOderArray.filter(order => (order.orderId?.includes(key) || order.deleiveryDetails?.selectedAddress?.mobileNumber?.includes(key)))
      setActiveOrderArray(filterresult)
    }
    event.preventDefault()

  };

  const assignPartner = (data, status) => {
    setSelectedAssignData(data);
    setShowAssignPopup(true)
    //updateOrderStatus(data, OrderStatus.PENDING)

  }

  const onConfirmSubmitAssign = (selectedItem, submitOrderResponse) => {
    console.log('submitOrderResponse', submitOrderResponse);
    updateOrderStatus(selectedItem, OrderStatus.ASSIGNED, submitOrderResponse)
    setShowAssignCourierPopup(false)

  }

  const onCloseSubmitAssign = () => {
    setShowAssignCourierPopup(false)

  }


  const onCloseProductItemPopup = () => {
    setShowProductsPopup(false)
    setSelectedOrderData(null)

  }

  const handleShowDetails = (data) => {
    setSelectedOrderData(data)
  };

  const handleShowBankDetails = (data) => {
    setShowBankInfoPopup(true)
    setSelectedOrderDataForBank(data)
  };
  const handleSelectCourierSelection = () => {
    setShowAssignPopup(false)
    setShowAssignCourierPopup(true)
  }

  const isSuperAdmin = () => {
    return (props?.user?.claims?.role === 'admin') || (props?.user?.claims?.role === 'superAdmin')
  }


  const cancelOrder = async (data, status = OrderStatus.COMPLETED) => {
    const body = {
      "ids": [
        data?.trackInfo?.order_id
      ]
    }
    try {
      await postAPI(CANCEL_ORDERS_URL, body)
    } catch (e) {
      console.log('cancelOrder failure', e)
      alert('Error in cancelOrder')
      return;
    }
    const updateOrder = data
    updateOrder["cancelStatus"] = OrderStatus.COMPLETED
    updateOrderStatus(updateOrder, status)
  }

  const processReturnOrder = async (data, status) => {
    const updateOrder = data

    if (updateOrder.assignedTo !== 'vendor') {
      try {
        await postAPI(RETURN_ORDERS_URL, data) //shiproket API call

      } catch (e) {
        console.log('processReturnOrder failure', e)
        alert('Error in processReturnOrder')
        return;
      }
    }
    updateOrder["returnStatus"] = ReturnStatus.RETURN_PROCESS
    updateOrderStatus(updateOrder, status)
  }

  const cancelReturnOrder = async (data, status) => {
    const updateOrder = data
    updateOrder["returnStatus"] = ReturnStatus.RETURN_CANCEL
    updateOrderStatus(updateOrder, status)
  }

  const completeReturnOrder = async (data, status) => {
    const updateOrder = data
    updateOrder["returnStatus"] = ReturnStatus.RETURN_COMPLETED
    updateOrderStatus(updateOrder, status)
  }
  const sendForRefund = async (data, walletResponse) => {
    const updateOrder = data
    updateOrder["returnStatus"] = updateOrder.cancelStatus==="completed"? null:ReturnStatus.REFUND_SEND
    updateOrder["walletResponse"] = walletResponse
    updateOrderStatus(updateOrder, OrderStatus.RETURN)
    setShowBankInfoPopup(false)
  }

  const sendForOutOfStockRefund = async (data, walletResponse,refund) => {
    const updateOrder = data
    const actualPrice = ()=> updateOrder?.orders ? updateOrder.orders.reduce((accumulator, object) => {
      return accumulator + parseInt(object.productDetails.currentPrice) * object.quantity;
  }, 0) : 0
    const totalPayment= Number(actualPrice())- Number(refund)
    updateOrder["walletResponse"] = walletResponse
    updateOrder.refundForOutOfStockAmount=refund
    updateOrder.refundForOutOfStockAmountStatus=ReturnStatus.REFUND_SEND
    updateOrder.paymentInfo.totalPayment=totalPayment
    updateOrder.lastUpdatedTime = Date.now()
    updateOrderWithoutStatus(updateOrder)
    setSelectedRefundDataForOutOfStock(null)
    setShowRefundPopup(false)
  }


  const submitOrderToVendor = (selectedOrder, selectedVendor) => {
    const order = selectedOrder
    order.assignedTo = 'vendor'
    order.vendordetails = selectedVendor
    updateOrderStatus(order, OrderStatus.ASSIGNED)
    setShowAssignPopup(false)
  }

  const onProductAvailablityUpdate= async (selectedOrderData)=>{
    onCloseProductItemPopup()
   if (selectedOrderData.paymentInfo.paymentStatus === 'success'){
     setSelectedRefundDataForOutOfStock(selectedOrderData)
     setShowRefundPopup(true)
   }
   else{
    const actualPrice = ()=> selectedOrderData?.orders ? selectedOrderData.orders.reduce((accumulator, object) => {
      return accumulator + parseInt(object.productDetails.currentPrice) * object.quantity;
  }, 0) : 0
    const totalPrice = ()=> selectedOrderData?.outOfStockItem ? selectedOrderData.outOfStockItem.reduce((accumulator, object) => {
      return accumulator + parseInt(object.productDetails.currentPrice) * object.quantity;
  }, 0) : 0

    const totalPayment= Number(actualPrice())- Number(totalPrice())
    const updateOrder=selectedOrderData
    updateOrder.refundForOutOfStockAmount=totalPrice()
    updateOrder.refundForOutOfStockAmountStatus="Amount Adjusted"
    updateOrder.paymentInfo.totalPayment=totalPayment
    updateOrder.lastUpdatedTime = Date.now()
    updateOrderWithoutStatus(selectedOrderData)

   }

  }

  const getColorBorder = () => {
    switch (selectedTab) {
      case OrderStatus.REJECTED:
        return "right-order-item red-left-border"
      case OrderStatus.PENDING:
        return "right-order-item"
      case OrderStatus.ACTIVE:
        return "right-order-item-gray"
      case OrderStatus.COMPLETED:
        return "right-order-item-green"
      case OrderStatus.ASSIGNED:
      case OrderStatus.INTRANSIT:
        return "right-order-item-light-green"
      case OrderStatus.RETURN:
        return "right-order-item-purple"
      default:
        return "right-order-item red-left-border"
    }
  }


  const OrderView = () => {
    return <div class="product-card margin-top-3p">
      <div class="card-body">

        <div className='title-container'>
          <h5 class="card-title ">{`All ${selectedTab?.toLocaleLowerCase()} orders`}  </h5>
          <h5 class="card-title ">{`Total (${activrOderArray.length})`}  </h5>

        </div>
        {loading && <Loader loading={loading} />}

        {activrOderArray.map((data) => {
          return <div className={getColorBorder()}>
            {((data.orderStatus === OrderStatus.RETURN) || (data.orderStatus === OrderStatus.CANCELED)) &&
              <div style={{ float: 'right' }}>
                {((data.returnStatus === ReturnStatus.RETURN_COMPLETED) ||
                  ((data.orderStatus === OrderStatus.CANCELED) && data.paymentInfo.paymentStatus === 'success')) &&
                  <Button className='primary-button' style={{ margin: 10, }}
                    onClick={() => handleShowBankDetails(data)}>{"Send Amount for Refund"}</Button>
                }
              </div>
            }
            <div className='bold-font order-title'>#{data.orderId}</div>

            {data?.assignedTo === 'vendor' ? <div><span>Assigned To Vendor:</span> {data?.vendordetails?.displayName}</div>
              :
              <>
                <div><span>Delivery Partner:</span> {data?.trackInfo ? "Assigned" : "Not Assigned"}</div>
                <div><span>Courier Status:</span> {data?.trackInfo ? "New" : "Pending"}</div>
                <div><span>ShipmentId:</span> {data?.trackInfo ? data?.trackInfo?.shipment_id : "---"}</div>
              </>
            }


            <div><span>Delivery Location:</span> {getAddress(data?.deleiveryDetails?.selectedAddress)}</div>
            <div><span>Mobile Number:</span> {data?.deleiveryDetails?.selectedAddress?.mobileNumber}</div>
            <div><span>Delivery Time:</span> {data?.deleiveryDetails?.deliveryTimeSlot}</div>
            <div><span>Order Status:</span> {data?.orderStatus}</div>
            {data?.cancelReason && <div><span>Cancel Reason:</span> {data?.cancelReason}</div>}
            <div><span>Products:</span> {data?.orders.map(ord => `${ord?.productDetails?.itemName} - (Quantity:${ord.quantity}), `)}
              <div onClick={() => handleShowDetails(data)} style={{ textDecoration: 'underline', marginLeft: 10, float: 'right' }}>Show Products</div></div>
            <div><span>Products Price:</span>
              {data?.orderStatus?.toLowerCase() === OrderStatus.RETURN ?
                data?.totalRefundAmount :
                Number(data?.paymentInfo?.totalPayment) - Number(data?.paymentInfo?.deliveryCharges)
              }
            </div>
            {data?.refundForOutOfStockAmountStatus && <div><span>Refund Status:</span> {data?.refundForOutOfStockAmountStatus}</div>}
            {data?.refundForOutOfStockAmount && <div><span>Refund Amount:</span> {data?.refundForOutOfStockAmount || 0}</div>}

            <div><span>Delivery Charges:</span> {data?.paymentInfo?.deliveryCharges || 0}</div>
            <div><span>Total Amount:</span> Rs. {data?.orderStatus === OrderStatus.RETURN ? data?.totalRefundAmount : data?.paymentInfo?.totalPayment}</div>
            <div><span>Payment Status:</span> {data?.paymentInfo?.paymentStatus.toUpperCase()}</div>
            <div><span>Order Date:</span> {moment(data?.orderDate).format('MMMM Do YYYY, h:mm:ss a')}</div>
            <div><span>Last Update Time:</span> {moment(data?.lastUpdatedTime).format('MMMM Do YYYY, h:mm:ss a')}</div>

            {((data?.orderStatus?.toLowerCase() === OrderStatus.RETURN)) &&
              <div><span>Return Status:</span> {data?.returnStatus || data?.orderStatus}</div>}
            {data?.orderStatus === OrderStatus.RETURN && <div><span>Return Reason:</span> {data?.returnReason}</div>}


            {isSuperAdmin() && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {data.orderStatus === OrderStatus.COMPLETED ? <div style={{ display: 'flex' }}>
                {/* <Button className='primary-button bg-black' onClick={() => updateOrderStatus(data, OrderStatus.ACTIVE)}>{"Move to Active"}</Button> */}
                <div style={{ fontWeight: '700', color: 'green', marginLeft: 10, alignSelf: 'center' }}>Completed</div>
              </div>
                :
                <>
                  {data.orderStatus === OrderStatus.PENDING ?
                    <>
                      <Button className='primary-button' style={{ margin: 10, }} onClick={() => assignPartner(data, OrderStatus.PENDING)}>{"Assign Courier Partner"}</Button>
                      <Button className='primary-button bg-black' style={{ margin: 10, }} onClick={() => updateOrderStatus(data, OrderStatus.ACTIVE)}>{"Move to Active"}</Button>
                    </>
                    :
                    data.orderStatus !== OrderStatus.CANCELED && data.orderStatus !== OrderStatus.REJECTED && data.orderStatus !== OrderStatus.PENDING && data.orderStatus !== OrderStatus.ASSIGNED && data.orderStatus !== OrderStatus.RETURN && data.orderStatus !== OrderStatus.INTRANSIT &&
                    <Button style={{ margin: 10, background: '#dc3545', border: '#dc3545' }} onClick={() => updateOrderStatus(data, OrderStatus.REJECTED)}>{"Reject Order"}</Button>}
                  {(data.orderStatus !== OrderStatus.REJECTED && data.orderStatus !== OrderStatus.PENDING && data.orderStatus !== OrderStatus.ASSIGNED && data.orderStatus !== OrderStatus.CANCELED && data.orderStatus !== OrderStatus.RETURN) && data.orderStatus !== OrderStatus.INTRANSIT ?
                   <> 
                   <Button className='primary-button bg-black' style={{ margin: 10, }} onClick={() => updateOrderStatus(data, OrderStatus.PENDING)}>{"Accept Order"}</Button>
                   <Button disabled={data.assignedTo === 'vendor'} className='primary-button  bg-green' style={{ margin: 10, }} onClick={() => updateOrderStatus(data, OrderStatus.COMPLETED)}>{"Mark as Complete"}</Button>
                   </>
                    :
                    data.orderStatus !== OrderStatus.ASSIGNED && data.orderStatus !== OrderStatus.CANCELED && data.orderStatus !== OrderStatus.PENDING && data.orderStatus !== OrderStatus.RETURN && data.orderStatus !== OrderStatus.INTRANSIT && <Button className='primary-button bg-black' onClick={() => updateOrderStatus(data, OrderStatus.ACTIVE)}>{"Move to Active"}</Button>
                  }
                  {data.orderStatus === OrderStatus.ASSIGNED &&
                    <>
                      <Button disabled={data.assignedTo === 'vendor'} className='primary-button  bg-green' style={{ margin: 10, }} onClick={() => updateOrderStatus(data, OrderStatus.COMPLETED)}>{"Mark as Complete"}</Button>
                      <Button className='primary-button bg-black' style={{ margin: 10, }} onClick={() => updateOrderStatus(data, OrderStatus.CANCELED)}>{"Cancel Order"}</Button>
                    </>
                  }

                  {data.orderStatus === OrderStatus.CANCELED && data.cancelStatus != OrderStatus.COMPLETED && data.orderStatus !== OrderStatus.RETURN &&
                    <>
                      <Button className='primary-button bg-black' style={{ margin: 10, }} onClick={() => updateOrderStatus(data, OrderStatus.ACTIVE)}>{"Move to Active"}</Button>

                      <Button className='primary-button bg-black' style={{ margin: 10, }} onClick={() => cancelOrder(data, OrderStatus.CANCELED)}>{"Cancel order from courier"}</Button>
                    </>
                  }

                  {data.orderStatus === OrderStatus.CANCELED &&
                    <>
                      <Button className='primary-button bg-black' style={{ margin: 10, }} onClick={() => updateOrderStatus(data, OrderStatus.ACTIVE)}>{"Move to Active"}</Button>

                    </>
                  }

                  {data.orderStatus === OrderStatus.INTRANSIT &&
                    <>
                      <Button className='primary-button  bg-green' style={{ margin: 10, }} onClick={() => updateOrderStatus(data, OrderStatus.COMPLETED)}>{"Mark as Complete"}</Button>
                    </>
                  }

                  {data.orderStatus === OrderStatus.RETURN &&
                    <>
                      {
                        (data.returnStatus === ReturnStatus.RETURN_PROCESS || data.returnStatus === ReturnStatus.RETURN_SEND) && data.assignedTo !== 'vendor' &&
                        <Button className='primary-button  bg-green' style={{ margin: 10, }} onClick={() => completeReturnOrder(data, OrderStatus.RETURN)} >{"Mark Return Completed"}</Button>
                      }
                      {!(data.returnStatus) && data.assignedTo !== 'vendor' &&
                        <>
                          <Button className='primary-button bg-black' style={{ margin: 10, }} onClick={() => cancelReturnOrder(data, OrderStatus.RETURN)} >{"Reject Return"}</Button>
                          <Button className='primary-button' style={{ margin: 10, }} onClick={() => processReturnOrder(data, OrderStatus.RETURN)} >{"Process Return"}</Button>
                        </>

                      }

                      {data.returnStatus === ReturnStatus.RETURN_COMPLETED && <div style={{ fontWeight: '700', color: 'green', marginLeft: 10, alignSelf: 'center' }}>Return Completed</div>
                      }
                    </>
                  }


                </>
              }
            </div>
            }

            {(data.orderStatus === OrderStatus.RETURN && data.assignedTo === 'vendor' && data.returnStatus !== ReturnStatus.RETURN_COMPLETED && data.returnStatus !== ReturnStatus.REFUND_SETTLE) &&
              <div style={{ marginTop: 10, fontSize: 20, color: 'green', textAlign: 'end' }}> Return order is sent to  vendor</div>
            }


            <hr />


          </div>
        })}

        {activrOderArray.length === 0 && <center>No Orders available...</center>}
      </div>
    </div>
  }


  return <>
    <Box sx={{ width: '100%', typography: 'body1' }}>

      <TabContext value={selectedTab}>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

          <TabList onChange={handleChange} aria-label="orderlist" variant="scrollable" >
            <Tab label="Active Orders" value={OrderStatus.ACTIVE} />
            <Tab label="Pending Orders" value={OrderStatus.PENDING} />
            <Tab label="Assigned Orders" value={OrderStatus.ASSIGNED} />
            <Tab label="In-Transit Orders" value={OrderStatus.INTRANSIT} />
            <Tab label="Completed Orders" value={OrderStatus.COMPLETED} />
            <Tab label="Return Orders" value={OrderStatus.RETURN} />
            <Tab label="Canceled Orders" value={OrderStatus.CANCELED} />
            <Tab label="Rejected Orders" value={OrderStatus.REJECTED} />
            <Tab label="Failed Orders" value={OrderStatus.FAILED} />

          </TabList>
          <SearchBar handleTextSearch={handleTextSearch} searchText={searchText} value={searchText} title={'Enter mobile number or orderId to search'} css={'search-right'} />

        </Box>

        <TabPanel value={OrderStatus.ACTIVE}><OrderView /></TabPanel>
        <TabPanel value={OrderStatus.PENDING}><OrderView /></TabPanel>
        <TabPanel value={OrderStatus.ASSIGNED}><OrderView /></TabPanel>
        <TabPanel value={OrderStatus.INTRANSIT}><OrderView /></TabPanel>
        <TabPanel value={OrderStatus.COMPLETED}><OrderView /></TabPanel>
        <TabPanel value={OrderStatus.RETURN}><OrderView /></TabPanel>
        <TabPanel value={OrderStatus.CANCELED}><OrderView /></TabPanel>
        <TabPanel value={OrderStatus.REJECTED}><OrderView /></TabPanel>
        <TabPanel value={OrderStatus.FAILED}><OrderView /></TabPanel>


      </TabContext>

      {showAssignPopup && <ProcessOrderPopup data={selectedAssignData} onCloseOrderProcessPopup={() => setShowAssignPopup(false)} show={true} selectedOrder={selectedAssignData} onSelectCourier={handleSelectCourierSelection} submitOrderToVendor={submitOrderToVendor} />}
      {showAssignCourierPopup && <AssignOrderPopup onCloseSubmitAssign={onCloseSubmitAssign} onConfirmSubmitAssign={onConfirmSubmitAssign} selectedOrder={selectedAssignData} />}
      {showProductsPopup && <OrderItemPopup data={selectedOrderData} onCloseProductItemPopup={onCloseProductItemPopup} show={true} onProductAvailablityUpdate={onProductAvailablityUpdate} />}
      {showBankInfoPopup && <BankDetailsPopup data={selectedOrderDataForBank} onClosePopup={() => setShowBankInfoPopup(false)} show={true} sendForRefund={sendForRefund} />}
      {showRefundPopup && <SendRefundPopup data={selectedRefundDataForOutOfStock} onClosePopup={() => setShowRefundPopup(false)} show={true} sendForOutOfStockRefund={sendForOutOfStockRefund} />}

      

    </Box>

  </>
}

export default OrderList;