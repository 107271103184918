
import React, { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';
import { ReturnStatus, OrderStatus } from './OrderEnum';
import { color } from '@mui/system';

function OrderItemPopup(props) {
    const [producutOutOfStockList, setproducutOutOfStockList] = useState(props.data.outOfStockItem|| []);
    const [orderList, setOrderList] = useState(props.data.orders || []);



    useEffect(() => {
        // const orderItems = props.data.orders
        // orderItems.map((product, index) => {
        //     if (product.stockAvailablity === false) {
        //         availablityList.push(index);
        //     }
        // })

        // setAvailablityList([...availablityList])

    }, [])


    function toggleProductAvailablity(index, item) {
        const orderItems = props.data.orders
        let updatedList = producutOutOfStockList
        if (producutOutOfStockList.some((pl => pl.productId === item.productId))) {
            updatedList = producutOutOfStockList.filter((product) => item.productId !== product.productId)
            item.stockAvailablity=true
            orderList.push(item)
        } else {
            item.stockAvailablity=false
            updatedList.push(item)
        }

        var updatedOrderList = orderList.filter(function (n1) {
            return !(updatedList.some(function (n2) {
                return n1.productId === n2.productId;
            }))
        });

        setproducutOutOfStockList([...updatedList])
        setOrderList([...updatedOrderList])

    }

    function sendForUpdate() {
        const order = props.data
        order.orders = orderList
        order.outOfStockItem = producutOutOfStockList
        props.onProductAvailablityUpdate(order)
    }


    const ProductItem=(item, index)=>{
        return <div className='wrapper-user' key={"user" + index}>
        <div className='row-home mt-20'>
            <img src={item?.productDetails?.imageUrl} alt="item" width="100" height="120" />
            <div className='column-home ml-20'>
                <div className='prd-name'>{item?.productDetails?.itemName}</div>
                <div><span>Quantity:</span> {item?.quantity}</div>
                <div><span>Category:</span> {item?.productDetails?.itemType}</div>
                {item?.productDetails?.size && <div><span>Size:</span> {item?.productDetails?.size}</div>}

                <div><span>Price Per Unit:</span> {item.productDetails?.currentPrice}</div>

                <div style={{ color: 'red', fontSize: 18, fontWeight: 'bold' }}>{producutOutOfStockList.some((pl => pl.productId === item.productId)) ? 'Out of Stock' : ''}</div>

                {item.productDetails.trackInfoUrl && <div style={{ wordWrap: 'break-word', maxWidth: '550px' }}><span>TrackUrl:</span><a href={item.productDetails.trackInfoUrl} target='_blank'> {item.productDetails.trackInfoUrl}</a></div>}
                {props.data?.orderStatus?.toLowerCase() === OrderStatus.ACTIVE && !props.data?.refundForOutOfStockAmountStatus &&
                    <Button variant="primary  bg-green" onClick={() => toggleProductAvailablity(index, item)} style={{ marginTop: 10, width: 200, alignSelf: 'flex-end' }}>
                        {producutOutOfStockList.some((pl => pl.productId === item.productId)) ? "Move to Available Stock" : "Mark as Out of Stock"}
                    </Button>
                }
            </div>
        </div>
    </div>
    }


    return (<>
        <Modal show={true} onHide={props.onCloseProductItemPopup} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title> {"Order List - Total Item (" + props.data.orders.length + ")"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                {orderList?.map((item, index) => {
                    return ProductItem(item, index)
                })}
                
                {producutOutOfStockList.length>0 &&<div style={{ fontSize: 20, borderTop: 1, borderColor: 'black', padding: 10, fontWeight: 'bold', color: 'blue' }}>Out of Stock</div>}

                {producutOutOfStockList?.map((item, index) => {
                    return  ProductItem(item, index)
                })}
            </Modal.Body>
            <Modal.Footer>
                <div className='flex-row justify'>
                    <div className='flex-row' style={{ display: 'contents' }}>
                        <div className='flex-row'>
                            <Button variant="secondary" onClick={props.onCloseProductItemPopup} style={{ marginRight: 10 }}>
                                Close
                            </Button>
                            {props.data?.orderStatus?.toLowerCase() === OrderStatus.ACTIVE && !props.data?.refundForOutOfStockAmountStatus &&
                             <Button variant="primary" onClick={sendForUpdate} style={{ marginRight: 10 }}>
                                Send for Update
                            </Button>
                            }
                        </div>
                        <div style={{ alignSelf: 'center', fontWeight: '600' }}>
                            <span>Total Amount:</span> Rs. {props.data?.orderStatus?.toLowerCase() === OrderStatus.RETURN ? props.data?.totalRefundAmount : props.data?.paymentInfo?.totalPayment}
                        </div>


                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    </>)



}

export default OrderItemPopup