import React, { useState, useEffect } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/login/login";
import Home from "../pages/home/home";
import UserList from "../pages/users/userList";
import Header from '../component/header';
import SideBar from '../pages/home/component/sidebar'
import { HomeBanner } from '../component/banner'
import firebase from 'firebase/compat/app';
import DashBoard from '../pages/home/component/dasboard';
import OrderList from '../pages/orders/orderList';
import ProductList from '../pages/products/productList';
import Catalogue from '../pages/products/catalogue'
import MemberList from '../pages/members/memberList';
import OfferPage from '../pages/offers/offerPage';
import NotificationManager from '../pages/notification/notificationManager';
import RefundList from '../pages/refund/refundList';
import { Commet } from 'react-loading-indicators';



function Router() {
  //const navigate = useNavigate();

  const [user, setUser] = useState()
  const [selectedPage, setSelectedPage] = useState('dashboard');
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    console.log('user--',user)
    setLoading(true)
    firebase?.auth()?.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        console.log('currentUserDe', user)
        setUser(user)
        fetchCustomClaims()
        setLoading(false)

        // 👈 Your code that needs an active user goes here
      } else {
        // User is signed out
        // ...
        // navigate("/")
        setLoading(false)
        window.open('/'); 

      }
    });

  }, [])

  const fetchCustomClaims = () => {
    firebase.auth().currentUser?.getIdTokenResult()
      .then((user) => {
        setUser(user)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlePageSelect = (page) => {
    setSelectedPage(page)
  }


  const UnAuthorizedEntry=()=>{
    return <div style={{display:'flex',justifyContent:'center',marginTop:100}}>
      <h1 >You are not authorized</h1>
    </div>
  }

  return (<>
    <BrowserRouter>
     {user && <>
     <HomeBanner user={user} />
      <SideBar onSelect={handlePageSelect} selectedPage={selectedPage} user={user} />
      </>
  }
      <div className={user?'main_container':''}>
        {/* <Header /> */}
        {loading?
       <div style={{display:'flex',justifyContent:'center',marginTop:100}}> 
     
      <Commet color="#4154f1" size="medium" text="" textColor="" />
      </div>
        :
        <Routes>
          <Route path="/" element={user?<DashBoard  user={user}/>:<Login />} />
          <Route exact path="/home" element={user?<DashBoard  user={user}/>:<UnAuthorizedEntry/>} />
          <Route exact path="/register" element={user?<UserList user={user}/>:<UnAuthorizedEntry/>} />
          <Route exact path="/dashboard" element={user?<DashBoard user={user}/>:<UnAuthorizedEntry/>} />
          <Route exact path="/catalogue" element={user?<Catalogue user={user}/>:<UnAuthorizedEntry/>}/>
          <Route exact path="/productList" element={user?<ProductList user={user}/>:<UnAuthorizedEntry/>}/>
          <Route exact path="/orders" element={user?<OrderList user={user}/>:<UnAuthorizedEntry/>} />
          <Route exact path="/member" element={user?<MemberList user={user}/>:<UnAuthorizedEntry/>} />
          <Route exact path="/offers" element={user?<OfferPage user={user}/>:<UnAuthorizedEntry/>} />
          <Route exact path="/notifications" element={user?<NotificationManager user={user}/>:<UnAuthorizedEntry/>} />
          <Route exact path="/refund" element={user?<RefundList user={user}/>:<UnAuthorizedEntry/>} />
          <Route path="*" element={<h1 >Page no found</h1>} />
        </Routes>
}
      </div>
    </BrowserRouter>
  </>

  );
}

export default Router;


