import firebase from 'firebase/compat/app';

const getToken=async ()=>{
  const user= await firebase.auth().currentUser?.getIdTokenResult()
  const token=await user?.token
  return token;
}
export const postAPI =async (url,bodyJson)=>{
  const bodyData=JSON.stringify(bodyJson)
  var headers = new Headers();
   headers.append("Authorization", "Bearer " + await getToken());
   headers.append("Content-Type", "application/json");

   var requestOptions = {
    method: 'POST',
    body: bodyData,
    headers: headers,
  };
  const response = await fetch(url, requestOptions).catch(error => console.log('error', error));
  const json = await response.json();
  return json
}


export const getAPI = async (url) => {
  var headers = new Headers();
   headers.append("Authorization", "Bearer " + await getToken());
  var requestOptions = {
    method: 'GET',
    headers: headers,
  };
  const response = await fetch(url, requestOptions).catch(error => console.log('error', error));
  const json = await response.json();
  return json
}

export const putAPI =async (url,bodyJson)=>{
  const bodyData=JSON.stringify(bodyJson)
  var headers = new Headers();
   headers.append("Authorization", "Bearer " + await getToken());
   headers.append("Content-Type", "application/json");
   var requestOptions = {
    method: 'PUT',
    body: bodyData,
    headers: headers,
  };
  const response = await fetch(url, requestOptions).catch(error => console.log('error', error));
  const json = await response.json();
  return json
}



export const deleteAPI =async (url,bodyJson)=>{
  const bodyData=JSON.stringify(bodyJson)
  var headers = new Headers();
   headers.append("Authorization", "Bearer " + await getToken());
   headers.append("Content-Type", "application/json");
   var requestOptions = {
    method: 'DELETE',
    body: bodyData,
    headers: headers,
  };
  
  const response = await fetch(url, requestOptions).catch(error => console.log('error', error));
  const json = await response.json();
  return json
}


export const uploadFile =async (url,formData)=>{
  var headers = new Headers();
   headers.append("Authorization", "Bearer " + await getToken());
   var requestOptions = {
    method: 'POST',
    body: formData,
    headers: headers,
  };
  const response = await fetch(url, requestOptions).catch(error => console.log('error', error));
  const json = await response.json();
  return json
}
