import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import './product.css';
import CatalogueDetailsCard from './component/catalogueDetailsCard'
import { getCatalogueList } from '../../redux/actions/catalogueAction';
import {useLocation} from 'react-router-dom';


  const CatalogueProduct=(props)=>{
    const location = useLocation();

    const data= location?.state?.data
   const [allCategory,setAllCategory]=useState([])
    const navigate = useNavigate();
    const dispatch = useDispatch();



    const categoryList = useSelector((state) => state.getCatalogueReducer);

    useEffect(() => {
    dispatch(getCatalogueList());
  }, [])

  useEffect(() => {
    if(categoryList){
      console.log('categoryList',categoryList)
     setAllCategory(categoryList)
    }

}, [categoryList])

const handlePageSelect=(page, data)=>{
  navigate("/"+page,{state:{data:data}})
  
}


    return <>
        <div className='row-home'>
          <div className='col-lg-8'>
            <div className='row'>
             
              <div className='column-home'>
              <CatalogueDetailsCard list={allCategory} handlePageSelect={handlePageSelect} selectedData={data} user={props.user}/>
              </div>
            </div>
          </div>

        </div>
    </>
  }

  export default CatalogueProduct;